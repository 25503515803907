<template>
    <v-col cols="12" class="mt-6 pt-6">
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-btn class="mb-2" color="warning" @click="addImage">
            <v-icon>mdi-plus</v-icon> AGREGAR IMÁGEN
        </v-btn>
        <v-btn class="mb-2 ml-6" color="primary" @click="dialog = true">
            <v-icon>mdi-file-image</v-icon> VER REFERENCIA
        </v-btn>
        <div class="py-3">
            <em> Nota: Cargar imágenes de <b>500px X 600px</b></em>
        </div>
        <draggable tag="div" class="grid_draggable" @end="endDrag" :group="{ name: 'people', pull: 'clone', put: false }">
            <v-col v-for="(img, i) in images" :key="i">
                <v-form :ref="`form_personal`">
                    <v-card tile elevation="0" class="personal" :data-order="`${img.order}`">
                        <v-tooltip bottom v-if="!!img.content_id">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn absolute :color="img.status_id == 'CTNT_PUBLISHED' ? 'success' : 'error'" fab x-small
                                    v-bind="attrs" v-on="on" @click="statusStaff(img)" right>
                                    <v-icon>{{ img.status_id == 'CTNT_PUBLISHED' ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ img.status_id == 'CTNT_PUBLISHED' ? 'Publicado' : 'Despublicado' }}</span>
                        </v-tooltip>
                        <v-img v-if="!img.img_load" :id="`item_${i}`" :src="require('@/assets/images/logo-img.jpeg')"
                            class="grey darken-4"></v-img>
                        <v-img v-else :id="`item_${i}`" :src="`${domain}img/web_commerce/${img.img}`"
                            class="grey darken-4"></v-img>
                        <v-card-title class="title px-0" style="color:#595758">
                            <v-text-field v-model="images[i].name" dense label="Nombres" :rules=requiredRule outline />
                        </v-card-title>
                        <v-card-title class="title px-0" style="color:#595758">
                            <v-text-field v-model="images[i].title" dense label="Puesto" :rules=requiredRule outline />
                        </v-card-title>
                        <v-file-input label="Seleccione el archivo" outlined dense clearable accept=".jpg,.png,.JPG,.PNG"
                            prepend-icon="" @change="setFile($event, i)" :rules="img.content_id != null ? [] : requiredRule">
                        </v-file-input>
                        <v-card-actions class="py-0">
                            <v-col class="text-center py-0">
                                <v-row>
                                    <v-col class="col-md-6">
                                        <v-btn color="success" small @click="storePersonal(img, i)">
                                            Guardar
                                        </v-btn>
                                    </v-col>
                                    <v-col class="col-md-6">
                                        <v-btn color="warning" small @click="verifyStatus(img)">
                                            Eliminar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>

        </draggable>
        <v-dialog v-model="dialog" persistent>
            <v-img :src="require('@/assets/images/referencia-personal.png')" />
            <v-btn block @click="dialog = false">CERRAR</v-btn>
        </v-dialog>
    </v-col>
</template>

<script>

import { mapState, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import Vue from 'vue'
import draggable from 'vuedraggable'

export default {
    name: 'PersonalWebCommerce',
    components: {
        draggable,
    },
    data: () => ({
        model: 0,
        dialog: false,
        dialogUploadImg: false,
        dataFile: null,
        overlay: false,
        domain: process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL_DEVEL,
        images: [],
        requiredRule: [
            v => !!v || 'El campo es requerido'
        ],
        files: [],
    }),
    computed: {

        ...mapState('master', ['token', 'url', 'loadingBtn', 'paramAlertQuestion'])

    },
    methods: {

        ...mapActions('master', ['errorRequest', 'requestApi', 'alertNotification']),

        ...mapMutations('master', ['setUrl', 'setLoadingBtn', 'setTitleToolbar']),

        setFile(event, i) {

            let divImg = document.getElementById("item_" + i).getElementsByClassName('v-image__image--cover')[0]

            if (typeof event != 'undefined' && event != null) {

                this.images[i].img_load = false
                setTimeout(() => { divImg.style.backgroundImage = "url('" + URL.createObjectURL(event) + "')" }, 100);
                this.images[i].img = event

            } else {

                this.images[i].img = null
                divImg.style.backgroundImage = "url('" + require('@/assets/images/logo-img.jpeg') + "')"

            }

        },

        getImgPersonal() {

            this.setUrl('administracion')
            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'dataWeb',
                }
            }).then(res => {

                this.images = res.data._embedded.administracion.filter(e => e.content_type_id == 'IMG_PERSONAL_WEB').map(obj => {

                    const { content_id, content_name, description, service_name, order, status_id } = obj

                    return {
                        content_id: content_id,
                        img: content_name,
                        name: description,
                        title: service_name,
                        status_id,
                        order,
                        img_load: true
                    }

                })

            }).then(() => {

            })

        },

        addImage() {

            this.images.unshift({
                content_id: null,
                img: null,
                name: null,
                title: null,
                img_load: false,
                order: this.images.length ? parseInt(this.images.reverse((a, b) => { return b.child_leaf_count - a.child_leaf_count })[0].order) + 1 : 0
            })

        },

        verifyStatus(item) {

            if (item.content_id != null) {

                Vue.swal({
                    html: "Está seguro de eliminar el contenido?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {

                    if (result.isConfirmed)
                        this.removeStaff(item)

                })

            } else {

                let index = this.images.indexOf(item)
                this.images.splice(index, 1)

            }

        },

        removeStaff(item) {

            this.overlay = true
            this.setUrl('administracion')
            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'deleteContent',
                    contentId: item.content_id
                }
            }).then(res => {

                this.overlay = false
                this.getImgPersonal()
                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).catch(() => {

                this.overlay = false
                const { status } = err.response
                if (status === 500 || status === 422) {
                    let response = err.response;
                    this.errorRequest({
                        data: {
                            datos: response.data.errors,
                            status: status,
                            response: response
                        }
                    })
                }

            })

        },

        statusStaff(item) {

            this.overlay = true
            this.setUrl('administracion')
            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'statusContent',
                    contentId: item.content_id
                }
            }).then(res => {

                this.overlay = false
                this.getImgPersonal()
                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).catch(() => {

                this.overlay = false
                const { status } = err.response
                if (status === 500 || status === 422) {
                    let response = err.response;
                    this.errorRequest({
                        data: {
                            datos: response.data.errors,
                            status: status,
                            response: response
                        }
                    })
                }

            })

        },

        storePersonal(item, i) {

            if (!this.$refs.form_personal[i].validate())
                return

            this.overlay = true

            this.setUrl('administracion')

            let formData = new FormData();

            formData.append('typeAction', 'storePersonalWeb')
            formData.append('urlFiles', process.env.VUE_APP_API_URL_FILES_IMG_WEBCOMMERCE)
            formData.append(0, this.images[i].img)
            formData.append('title', this.images[i].title)
            formData.append('name', this.images[i].name)
            formData.append('order', this.images[i].order)

            if (this.images[i].content_id != null)
                formData.append('content_id', this.images[i].content_id)

            axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                }
            ).then(res => {

                this.dialogUploadImg = false

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                this.files = []
                this.overlay = false

            }).catch(err => {

                this.dialogUploadImg = false
                const { status } = err.response

                if (status === 500 || status === 422) {
                    let response = err.response;
                    this.errorRequest({
                        data: {
                            datos: response.data.errors,
                            status: status,
                            response: response
                        }
                    })
                }

                this.overlay = false

            }).then(() => {

                this.overlay = false

            })

        },

        endDrag(e) {

            document.getElementsByClassName('personal').forEach((ele, i) => {
                console.log(ele.dataset.order, i)
                this.images[ele.dataset.order].order = i
            });

            let newOrder = this.images.map(obj => {
                return {
                    order: obj.order,
                    content_id: obj.content_id
                }
            })

            this.setUrl('administracion')
            this.requestApi({
                method: 'POST',
                data: {
                    typeAction: 'reOrderContent',
                    newOrder
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })


            }).catch(() => {

                const { status } = err.response
                if (status === 500 || status === 422) {
                    let response = err.response;
                    this.errorRequest({
                        data: {
                            datos: response.data.errors,
                            status: status,
                            response: response
                        }
                    })
                }

            })

        },

    },
    created() {

        this.getImgPersonal()
        this.setTitleToolbar('PERSONAL DE EMPRESA')
    }
}

</script>

<style>
.grid_draggable {
    flex-wrap: wrap;
    display: flex;

}

.grid_draggable>.col {
    width: 25% !important;
    max-width: 25% !important;

}

@media(max-width: 810px) {
    .grid_draggable>.col {
        width: 50% !important;
        max-width: 50% !important;
    }
}

@media(max-width: 550px) {
    .grid_draggable>.col {
        width: 100% !important;
        max-width: 100% !important;
    }
}
</style>